// Header style scss
$nav-max-width : 200px;

body>header{

    .navbar ul{
        margin-bottom:0px;
    }

    #profile-min-img{
        border-radius: 8px;
        margin:auto 20px;
    }
    h1{
        font-size: 1.2em;
        font-weight: bold;
    }
}

body>header + div{
    &{
        position:absolute;
        margin-top:-1px; //Pour la bordure du nav
        z-index:99;
        width:$nav-max-width;
    }
    nav{
        width:$nav-max-width;
        /*padding:$nav-max-width/10;*/
        background:white;
        border: 1px solid $border-color;
        box-shadow: $shadow;
    }

    li {
        padding: 0 1rem;
        transition: 0.3s;
    }

    /*li:hover{
        background-color: #dedee9;
        transition: 0.3s;
    }*/


    #navcollapse-se .nav-link{
        padding-left : 2em !important;
    }
}

#notify-navbar{
    .dropdown{
        margin: 0 5px;
    }
    .dropdown-toggle{
        color: white;
    }

    .dropdown-toggle:after {
        content: none
    }

    .dropdown-menu{
        min-width: 330px;
        height: 360px;
        top: 53px;
    }

    .notify-drop-title {
        border-bottom: 1px solid #e2e2e2;
        padding: 5px 15px 10px 15px;
    }

    .drop-content{
        height: 280px;
        overflow-y: scroll;

        &>li{
            border-bottom: 1px solid #e2e2e2;
            padding: 10px 10px 5px 10px;

            &> p{
                margin: 5px 0;
            }
        }

        &>li:after{
            content: "";
            clear: both;
            display: block;
        }

        &>li:hover{
            background-color: #fcfcfc;
        }

        &>li:last-child{
            border-bottom: none;
        }

        .title {
            /*font-size: 15px;*/
            color: $blue;
        }

        .content {
            color: #4d4d4d;
            font-size: 14px;
        }
    }

    .rIcon{
        float: right;
        opacity: 1;
    
        &:hover{
            opacity: 0.7;
        }
    }

    .rIcon-read{
        color: #999;
    }

    .rIcon-not-read{
        color: $blue;
    }

    .notify-index {
        position: absolute;
        right: -5px;
        top: 0;
        background-color: orange;
        padding: 0px 4px;
        border-radius: 3px;
        font-weight: bold;
        font-size: 12px;
    }

    .notify-drop-footer {
      border-top: 1px solid #e2e2e2;
      bottom: 0;
      position: relative;
      padding: 3px 15px;

      a {
            color: #777;
            text-decoration: none;

            &:hover{
                color: #333;
            }
        }
    }
}