// Custom variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

/* import the necessary Bootstrap files */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";


$blue: #283583; //BLEU CLP

$primary:       $blue;
$secondary:     lighten($blue,25%);
$light: #d0d9e1;

$headings-font-weight: 600;
$card-border-radius: 20px;
//$table-bg: white;
// $dropdown-border-radius: 20px;

$theme-colors: (
  "primary": #283583,
  "secondary": #d4aa69,
  "other": #d0d9e1
);

$custom-file-text: (
  en: "Browse",
  fr: "Télécharger"
);

$link-color:                $primary;
$link-hover-color:          lighten($link-color, 25%);

$btn-transition:              color .4s ease-in-out, background-color .4s ease-in-out, border-color .4s ease-in-out, box-shadow .4s ease-in-out;


$navbar-dark-color:                 $gray-100;
$navbar-dark-hover-color:           $gray-300;
$navbar-dark-active-color:          $white;
$navbar-dark-disabled-color:        $gray-600;
$navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$navbar-dark-toggler-border-color:  none;

//collapse
$transition-collapse:         height .4s ease-in-out;

$input-bg:                              $gray-100;
$input-focus-bg:                        $gray-200;
$input-border-radius:                   20px;
$input-padding-y:                       0px;
