// /assets/app.scss

/** BOOTSTRAP **/

// customize some Bootstrap variables
@import "customizeBootstrap";

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";

/** LOCAL VARS **/

$font-stack : 'Sofia Pro', 'Lato';
$primary-color : #2f2f2f;
$blue: #283583;
$ocher: #d4aa69;
// $ocher: #cd9b52;
$gray-border: #dfe1e5;

$bkgd-color: rgba(40, 53, 131, 0.05);

$padding-xy: 20px;

$shadow: 0 3px 6px 0 rgba(0,0,0,0.3);
$border-radius-section : 20px;
$border-color: #dfe1e5;

/** IMPORTS **/
@import 'header';

/* FONTS */
@font-face {
    font-family: 'Sofia Pro Extra';
    src: url('../font/SofiaPro-ExtraLight.eot');
    src: url('../font/SofiaPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../font/SofiaPro-ExtraLight.woff2') format('woff2'),
        url('../font/SofiaPro-ExtraLight.woff') format('woff'),
        url('../font/SofiaPro-ExtraLight.ttf') format('truetype'),
        url('../font/SofiaPro-ExtraLight.svg#SofiaPro-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
}

/*@font-face {
    font-family: 'Sofia Pro';
    src: url('../font/SofiaPro-Bold.eot');
    src: url('../font/SofiaPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../font/SofiaPro-Bold.woff2') format('woff2'),
        url('../font/SofiaPro-Bold.woff') format('woff'),
        url('../font/SofiaPro-Bold.ttf') format('truetype'),
        url('../font/SofiaPro-Bold.svg#SofiaPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}*/

@font-face {
    font-family: 'Sofia Pro';
    src: url('../font/SofiaPro-Medium.eot');
    src: url('../font/SofiaPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../font/SofiaPro-Medium.woff2') format('woff2'),
        url('../font/SofiaPro-Medium.woff') format('woff'),
        url('../font/SofiaPro-Medium.ttf') format('truetype'),
        url('../font/SofiaPro-Medium.svg#SofiaPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../font/SofiaPro-Black.eot');
    src: url('../font/SofiaPro-Black.eot?#iefix') format('embedded-opentype'),
        url('../font/SofiaPro-Black.woff2') format('woff2'),
        url('../font/SofiaPro-Black.woff') format('woff'),
        url('../font/SofiaPro-Black.ttf') format('truetype'),
        url('../font/SofiaPro-Black.svg#SofiaPro-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../font/SofiaPro-Light.eot');
    src: url('../font/SofiaPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../font/SofiaPro-Light.woff2') format('woff2'),
        url('../font/SofiaPro-Light.woff') format('woff'),
        url('../font/SofiaPro-Light.ttf') format('truetype'),
        url('../font/SofiaPro-Light.svg#SofiaPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../font/SofiaPro-SemiBold.eot');
    src: url('../font/SofiaPro-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../font/SofiaPro-SemiBold.woff2') format('woff2'),
        url('../font/SofiaPro-SemiBold.woff') format('woff'),
        url('../font/SofiaPro-SemiBold.ttf') format('truetype'),
        url('../font/SofiaPro-SemiBold.svg#SofiaPro-SemiBold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Sofia Pro Ultra';
    src: url('../font/SofiaPro-UltraLight.eot');
    src: url('../font/SofiaPro-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('../font/SofiaPro-UltraLight.woff2') format('woff2'),
        url('../font/SofiaPro-UltraLight.woff') format('woff'),
        url('../font/SofiaPro-UltraLight.ttf') format('truetype'),
        url('../font/SofiaPro-UltraLight.svg#SofiaPro-UltraLight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../font/SofiaProRegular.eot');
    src: url('../font/SofiaProRegular.eot?#iefix') format('embedded-opentype'),
        url('../font/SofiaProRegular.woff2') format('woff2'),
        url('../font/SofiaProRegular.woff') format('woff'),
        url('../font/SofiaProRegular.ttf') format('truetype'),
        url('../font/SofiaProRegular.svg#SofiaProRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}



/** APP scss **/


body {
    font-size: 100%;
    color: $primary-color;
    background-color:$bkgd-color;
    
    font-family: 'Sofia Pro';
    font-weight: normal;
    font-style: normal;
}


#deco-left-img{
    /*width:10vw;
    height:35vh;*/
    position:fixed;
    bottom:0px;
    left:0px;
    z-index: -1;
}
#deco-right-img{
    /*width:10vw;
    height:45vh;*/
    position:fixed;
    bottom:0px;
    right:20px;
    z-index: -1;
}
.page-content{
    /*padding: 20px ($nav-max-width/2 + 50px);*/
    padding: 20px 0;
    transition: padding-left .4s easeinout;
    max-width: 65em;
    margin: auto;
}

.white-wrapper{
    background-color: white;
    border: 1px solid $border-color;
    box-shadow: $shadow;
    padding: 20px;
}

.radius{
    border-radius: $border-radius-section;
}

.bg-blue-kitn{
    background-color:$blue;
    color: white;
}

.btn-kitn{
    @extend .bg-blue-kitn;
    border-radius: 10px;
    font-weight:bold;
}
.blue-title{
    font-weight: bold;
    color: $blue;
}

.label-kitn{
    background : $ocher;
    color : white;
    font-size: 0.9em;
    padding : 3px 6px;
    margin-right : 1px;
    line-height: 1.5rem;
    border-radius:6px;
    white-space: nowrap;
}

.label-date{
    background : #4C59A7;
    color : white;
    font-size: 1em;
    padding : 5px 6px;
    margin-right : 1px;
    line-height: 1.5rem;
    border-radius: 20px;
    white-space: nowrap;
    display: inline-block;
    position: absolute;
    bottom: 0;

}
.labels-container{
    margin-bottom:20px;
    max-width:70%;
}

// Documents
.doc-bubble-container{
    &{
        display: flex;
        flex-wrap: wrap;
    }
    .doc-bubble{
       display:flex;
       justify-content:center;
       align-items:center;
       border-radius: 50%;
       width: 45px;
       height: 45px;
       font-size: 14px;
       margin : 6px;
       color:white;
    }
    p{
       font-weight: bold;
       margin: 1em auto;
       text-align: center;
       text-overflow: clip;
       overflow: hidden;
       white-wrap: nowrap;
   }
}

#profile-documents{
    .doc-bubble{
        width: 55px;
        height: 55px;
        font-size: 12px;
    }
}

.red{
   background-color: rgb(199, 73, 74);
}
.orange{
       background-color: rgb(255, 182, 78);;
}
.green{
       background-color: rgb(85, 185, 73);
}

/*.card-header:nth-last-of-type(2){
    border-bottom-left-radius: $border-radius-section;
    border-bottom-right-radius: $border-radius-section;
}*/

.card-documents{
    border-bottom-left-radius:0;
    border-bottom-right-radius:0;

    .card-header{
        background-color: white;
    }

    .doc-bubble-container{
        justify-content: center;
    }
}

.card-header:first-child{
    border-top-left-radius: $border-radius-section;
    border-top-right-radius: $border-radius-section;
}



// TODO: Mettre dans un _partial.scss
/**** SUBSCRIBE ***/


$padding-x:40px;
$border-stroke:1px;
.kitn-section{
    &{
        @extend .bg-white;
        padding:$padding-x;
        padding-top:0px;
        border: $border-stroke solid $border-color;
        border-radius: $border-radius-section;
        box-shadow: $shadow;
    }
    .kitn-title{
        @extend .bg-blue-kitn;
        margin-bottom: 20px;
        margin-left: -($border-stroke+$padding-x);
        margin-right: -($border-stroke+$padding-x);
        padding : 12px;
        border-radius: $border-radius-section;
        font-size:1.3em;
        font-weight: bold;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding-bottom: 10px;
        padding-left: 18px;
    }
}

section#add-member{
    min-width:650px;
    margin-top: 50px;
}

/*** END SUBSCRIBE ***/

/** PROJECT **/

.nav-kitn{
    &{
        //border: 1px solid $blue;
        border-radius:$border-radius-section;
        overflow:hidden;
        box-shadow: $shadow;
    }
    .nav-item{
        &{
            border-radius : 0px;
            border-right : 1px solid $gray-border;
        }

        &:last-child{
            border-right: none;
        }
        .nav-link{
            &{
                border-radius:0px;
                padding:5px;
                font-weight: bold;
                background: white;
                color:$blue;
            }
            &.active{
                background: $ocher;
                color: white;
            }
        }
    }
    & + .tab-content{
        margin-top:30px;
    }
}

.project-section{
    border-radius: $border-radius-section;
    padding : 13px $padding-x;
    margin-top: 20px;
    box-shadow: $shadow;
}

#project-info{
    &{
        @extend .project-section;
    }
    table.dates{
        &{
            margin-bottom:20px;
        }
        th,td{
            padding : 0px 10px;
        }
    }
}

#project-planning{
    &{
        @extend .project-section;
    }
}

.label__min-width input{
    min-width: 272px;
}

.modal-doc .modal-body > *{
    margin-bottom: 6px;
}

/** END PROJECT **/

/** P R O F I L E **/
.news {
    border: 1px solid grey;
    border-radius: 8px;
    padding: 8px;
}

.img-profile {
    border-radius: 6px;
    display: inline-block;
    /*width: 35%;*/
    min-height: 255.5px;
}

.studies {
    font-size: 13px;
    padding: 5px;
}

.studies-plus {
    font-size: 14px;
}

.border-title {
    display: flex;
    position: relative;
    top: 24px;
}

.border-title::before,
.border-title::after {
    content: "";
    flex: 1 1;
    background: grey;
    height: 1px;
    align-self: center;
}

.border-actu {
    border: 1px solid grey;
    padding: 10px;
    padding-top: 20px;
    border-top: none;
    transition: all .2s;
}

.border-actu:hover {
    box-shadow: 2px 2px 3px #888, -2px 2px 3px #888;
}

.container-details {
    display: flex;
}

.box-info {
    font-size: 15px;
    margin-left: 10px;
    position: relative;
    .box-info__roles {
        display: inline-block;
        position: absolute;
        bottom: 0;
    }
    .box-info__name{
        font-size: 16px;
        color: HSL(45, 84%, 56%);
        font-weight: bold;
        display: inline-block;
        margin-bottom: 5px;
    }
    .box-info__details{
        font-size: 16px;
        letter-spacing: 1px;
    }
}




td.spaceUnder {
padding-bottom: 2em;
}
/** END  P R O F I L E **/


/** C L I E N T **/
.client-box{
    transition: all .3s;
    &:hover{
        box-shadow: 1px 1px 8px #555;
    }
}
.project-title{
    color: $info;
}

.client-label{
    color: #858617;
    margin-bottom: 0;
}
a:hover{
    text-decoration: none;
    // transition: 0.3s;
}

.box-actions{
    display:flex;
    justify-content: space-around;
}
/** END C L I E N T **/

.smart-title{
    /* font-family: 'Noto Serif', serif;*/
    color: $blue;
}

.smart__line{
    color: $blue;
    border: 1px solid $blue;
    text-align: center;
    width: 200px;
}

.smart-box{
    &{
        border: 3px solid $blue;
        border-radius:30px 0;
        overflow:hidden;
        padding: 20px 8px;
    }
}